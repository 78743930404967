<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue"
import {
    createSchedule,
    deleteExecution,
    getExecutionsWithRecordings,
    listSchedules,
    runSchedule,
    runTestcases,
    saveExecution,
    saveSchedule,
} from "@/services/ProjectService"

import VActionButton from "@/common/components/form/VActionButton.vue"
import { prepareFlattenedTreeOfDependency } from "@/services/TestcaseHelperFunction.js"
import { ExecUpdater } from "@/services/RecordingService"
import { useRouter } from "vue-router"
import { formatDate } from "@/common/util"
import { useStore } from "@/store/useStore"
import { storeToRefs } from "pinia"
import ProjectSelection from "@/common/components/ProjectSelection.vue"
import ExecutionTime from "@/common/components/ExecutionTime.vue"
import VHelpText from "@/common/components/layout/VHelpText.vue"
import RecordingCellWithHoverPanel from "@/common/pivottable/RecordingCellWithHoverPanel.vue";
import TestNameHeader from "./TestNameHeader.vue"
import { getScreenCoordinates } from "@/services/DomHelperFunctions"
import type { ExecutionDTO, ProjectDTO, RecordingDTO, ScheduleDTO, TestCaseDTO} from "@/types/gen"

interface Props {
    project: ProjectDTO
    tests: TestCaseDTO[]
    isRunning: boolean
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "moveToProject", data: {testcase: TestCaseDTO, project: ProjectDTO}): void
    (event: "createTestCase"): void
    (event: "duplicateTestCase", data: {testcase: TestCaseDTO, withDescendants: boolean}): void
    (event: "deleteTestCase", data: {testcase: TestCaseDTO, withDescendants: boolean}): void
    (event: "update:isRunning", data: boolean): void
}>()

const router = useRouter()

const executions = ref<ExecutionDTO[]>([])
const filter = ref("")
const increaseOfNumberOfExecutions = ref(10)
const numberOfExecutionsToShow = ref(0)
const pageSize = 10
const pageNumber = ref(0)
const lastRecordings = ref<any>(null)
const flattenedDependencyTree = ref<any[]>([])
const collapsedItems = ref<any>({})
const visitedRecording = ref<any[]>([])
const schedules = ref<ScheduleDTO[]>([])
const testsLoaded = ref(false)
const executionsLoaded = ref(false)
const execUpdater = ref<any>(null)
const recordingHoverTimeout = ref<number | null>(null)

const store = useStore()
const { lock } = storeToRefs(store)

onMounted(async () => {
    numberOfExecutionsToShow.value = increaseOfNumberOfExecutions.value
    collapsedItems.value = JSON.parse(localStorage.getItem("collapsedItems") || "{}")
    await reload()
})

onUnmounted(() => {
    if (execUpdater.value) execUpdater.value.destroy()
})

const flattenedDependencyTreeFiltered = computed(() => {
    return flattenedDependencyTree.value.filter((item: any) => shouldShowItem(item))
})

const hasExecutions = computed(() => {
    return !!executions.value.length
})

const hasSchedules = computed(() => {
    return !!schedules.value.length
})

const uniqueExecutionTimes = computed(() => {
    let executionTime: any[] = []
    executions.value.forEach((execution, index) => {
        let format = formatDate(execution.time)
        let comparator = executionTime[executionTime.length - 1]
        if (!comparator || format != comparator[1]) {
            executionTime.push([index, format])
        }
    })
    return executionTime
})

const expectedPageSize = computed(() => {
    return pageSize * Math.pow(2, pageNumber.value)
})

const moreAvailable = computed(() => {
    return executions.value.length >= expectedPageSize.value
})

const marginTopMain = computed(() => {
    return hasSchedules.value ? `100px` : `130px`
})

const runningScheduleIds = computed(() => {
    return executions.value.filter((exec: any) => exec.status=='RUNNING')
        .map((exec: any) => exec.scheduleId)
})

watch(() => props.tests, reload)

watch(
    () => executions.value.length,
    () => startExecUpdater(true)
)

function schedulePlayDisabled(schedule: any) {
    return !checkedTestIds(schedule).length || runningScheduleIds.value.includes(schedule.id)
}

function updateRecordings(executionId: number, executionRecordings: any) {
    let execution = executions.value.find((e: any) => e.id == executionId)

    for (let r of executionRecordings) {
        let recToUpdate = execution!.recordings!.find((rec: any) => rec.id == r.id)
        if (recToUpdate) Object.assign(recToUpdate, r)
        else execution!.recordings!.push(r)
    }
}

function resetExecUpdater() {
    if (execUpdater.value) execUpdater.value.destroy()
    execUpdater.value = new ExecUpdater()
    execUpdater.value.setFinishedCallback(async () => {
        await testsFinished()
    })
    execUpdater.value.setExecUpdateCallback((exec: any, execRecordings: any) => {
        updateRecordings(exec.id,  execRecordings)
    })
}

function startExecUpdater(reset = false) {
    if (reset) resetExecUpdater()
    executions.value.map((exec: any) => {
        execUpdater.value.keepUpdated(exec)
    })
}

async function reload() {
    resetExecUpdater()
    flattenedDependencyTree.value = prepareFlattenedTreeOfDependency(props.tests, collapsedItems.value)
    saveCollapsedItems()

    pageNumber.value = 0
    await loadPage()
}

async function loadPage() {
    const promises: Promise<any>[] = [
        getExecutionsWithRecordings(props.project.id,  0, expectedPageSize.value),
        reloadSchedules(),
    ]
    executions.value = await promises[0]

    emit("update:isRunning", !!runningScheduleIds.value.length)

    enrichExecutionsWithTestResults()

    executionsLoaded.value = true
    if (hasExecutions.value) {
        const lastRecordings = executions.value[0].recordings
        props.tests.map((test: any) => {
            const lastRecording = lastRecordings!.find((r: any) => r.testCaseId == test.id)
            if (lastRecording && lastRecording.status === "RUNNING") {
                test.runMode = true
            } else {
                test.runMode = false
            }
        })
    }
    await promises[1]
    testsLoaded.value = true
    await startExecUpdater()
}

async function reloadSchedules() {
    schedules.value = await listSchedules(props.project.id)
}

function enrichExecutionsWithTestResults() {
    executions.value.map((exec) =>
        Object.assign(exec, {
            testResults: props.tests.map((test: any) => ({
                recordings: exec.recordings!.filter((r: any) => r.testCaseId == test.id),
            })),
        })
    )
}

async function doSaveExecution(execution: ExecutionDTO) {
    await saveExecution(execution.id, { name: execution.name! })
}

async function removeExecution(execution: ExecutionDTO) {
    executions.value.splice(executions.value.indexOf(execution), 1)
    await deleteExecution(execution)
}

async function doCreateSchedule() {
    const schedule = {
        name: "Schedule",
        projectId: props.project.id,
    }
    await createSchedule(schedule)
    await reloadSchedules()
}

async function testsFinished() {
    await reload()
}

async function replayTests(comment: string, testIds: number[]) {
    await runTestcases(props.project, comment, testIds)
    await reload()
}

async function playCheckedTests(schedule: ScheduleDTO) {
    await lock.value
    await runSchedule(schedule, "Run by " + schedule.name)
    await reload()
}

function checkedTestIds(schedule: any) {
    return schedule.testCases.filter((item: any) => !!item.active).map((item: any) => item.testCaseId)
}

function openModal(recordingId: number, event: MouseEvent) {
    const newPath = `/project/${props.project.id}/view/${recordingId}`
    const point = getScreenCoordinates(event)
    sessionStorage.setItem('animate', JSON.stringify([point.x, point.y]))
    router.push({ path: newPath })
}

function showTimeData(indexExecution: number) {
    for (let time of uniqueExecutionTimes.value) {
        if (indexExecution == time[0]) return true
    }
    return false
}

function dropDownItems(testcase: any) {
    if (testcase.isPredecessor) {
        return [
            
            {
                iconClass: ["fas", "fa-file"],
                text: "Single Test",
                callback: () => startEditor(testcase),
                nextLevel: [
                {
                iconClass: ["fas", "fa-play"],
                text: "Play",
                callback: () => replayTests("", testcase.id),
            },
            {
                iconClass: ["fas", "fa-edit"],
                text: "Go to Editor",
                callback: () => startEditor(testcase),
            },
                    {
                        iconClass: ["far", "fa-clone"],
                        text: "Duplicate Test Case",
                        disabled: testcase.runMode,
                        callback: () => duplicateTest(testcase, false),
                    }
                ]
            },
            {
                iconClass: ["fas", "fa-code-branch"],
                text: "Entire Tree",
                callback: () => startEditor(testcase),
                nextLevel: [
                    {
                        iconClass: ["fas", "fa-play"],
                        text: "Play successor tests",
                        callback: () => replayTests("", getItemsByPredecessorIds({ testcase })),
                        hidden: !countItemsByPredecessor({ testcase }),
                    },
                    {
                        iconClass: ["fas", "fa-paste"],
                        text: "Move to Project",
                        disabled: testcase.runMode,
                        callback: () => openMoveToProjectDialog(testcase, true),
                    },
                    {
                        iconClass: ["far", "fa-clone"],
                        text: "Duplicate Test Cases",
                        disabled: testcase.runMode,
                        callback: () => duplicateTest(testcase, true),
                    },
                    {
                        iconClass: ["fas", "fa-trash"],
                        text: "Delete Test Cases",
                        confirm: true,
                        disabled: testcase.runMode,
                        callback: () => deleteTest(testcase, true),
                    }
                ]
            }
        ]
    }
    return [
        {
            iconClass: ["fas", "fa-play"],
            text: "Play",
            callback: () => replayTests("", testcase.id),
        },
        {
            iconClass: ["fas", "fa-play"],
            text: "Play successor tests",
            callback: () => replayTests("", getItemsByPredecessorIds({ testcase })),
            hidden: !countItemsByPredecessor({ testcase }),
        },
        {
            iconClass: ["fas", "fa-edit"],
            text: "Go to Editor",
            callback: () => startEditor(testcase),
        },
        {
            iconClass: ["fas", "fa-paste"],
            text: "Move to Project",
            disabled: testcase.runMode,
            callback: () => openMoveToProjectDialog(testcase, false),
        },
        {
            iconClass: ["far", "fa-clone"],
            text: "Duplicate Test Case",
            disabled: testcase.runMode,
            callback: () => duplicateTest(testcase, false),
        },
        {
            iconClass: ["fas", "fa-trash"],
            text: "Delete Test Case",
            confirm: true,
            disabled: testcase.runMode,
            callback: () => deleteTest(testcase, false),
        },
    ]
}

function startEditor(testcase: any) {
    router.push(`/run/${testcase.id}`)
}

function openMoveToProjectDialog(testcase: any, withDescendants: boolean = false) {
    testcase.move = true
    testcase.withDescendants = withDescendants
}

function moveToProject({testcase, project}: any) {
    testcase.move = false
    emit("moveToProject", { testcase, project })
}

function duplicateTest(testcase: any, withDescendants: boolean) {
    emit("duplicateTestCase", { testcase, withDescendants })
}

function deleteTest(testcase: any, withDescendants: boolean) {
    emit("deleteTestCase", { testcase, withDescendants })
}

async function showMore() {
    pageNumber.value += 1
    await loadPage()
}

function getScheduleTestcase(schedule: any, testCaseId: number) {
    const foundEntry = schedule.testCases.find((item: any) => item.testCaseId == testCaseId)
    if (foundEntry) return foundEntry
    const newEntry = { testCaseId: testCaseId, active: false }
    schedule.testCases.push(newEntry)
    return newEntry
}

async function activeChanged(schedule: any, testCaseId: number, event: Event) {
    const checked = (event.target as HTMLInputElement).checked ?? false
    getScheduleTestcase(schedule, testCaseId).active = checked
    removeUnusedTestCaseVariables(schedule)
    store.runLocked(async () => await saveSchedule(schedule))
}

function removeUnusedTestCaseVariables(schedule: any) {
    schedule.testCases = schedule.testCases.map((item: any) => {
        return {
            ...item,
            testCaseId: item.testCaseId,
            maxAttempts: item.maxAttempts || 1,
            minPass: item.minPass || 1,
            stage: item.stage || 1,
        }
    })
}

function shouldShowItem(item: any) {
    if (filter.value && !item.search.some((s: string) => s.includes(filter.value.toLowerCase()))) return false
    while (item) {
        if (item.testcase.predecessorId === item.testcase.id) {
            return true;
        } else {
            item = getItemByTestcaseId(item.testcase.predecessorId);
            if (item?.isCollapsed) return false
        }
    }
    return true
}

function getItemsByPredecessor(item: any): any {
    let subitems = getItemsByPredecessorId(item.testcase.id)
    return subitems.reduce((items, subitem) => {
        if (subitem.testcase.id === item.testcase.id) {
            return items
        } else {
            return items.concat(getItemsByPredecessor(subitem));
        }
    }, subitems)
}

function getItemsByPredecessorIds(item: any): any {
    return getItemsByPredecessor(item).map((item: any) => item.testcase.id)
}

function countItemsByPredecessor(item: any): any {
    return getItemsByPredecessor(item).length
}

function getItemByTestcaseId(testcaseId: any) {
    return flattenedDependencyTree.value.find((item: any) => item.testcase.id == testcaseId)
}

function getItemsByPredecessorId(testcaseId: any) {
    return flattenedDependencyTree.value.filter((item: any) => item.testcase.predecessorId == testcaseId)
}

function saveCollapsedItems() {
    localStorage.setItem("collapsedItems", JSON.stringify(collapsedItems.value))
}

defineExpose({
    reload,
})
</script>

<template lang="pug">
.executions.tu-tab-area-left-margin
    .container-block.recording-list.pt-4.pb-5
        .spinner-container(v-if="!executionsLoaded", style="top: 65%; left: 70%")
            .spinner
                .spinner-item
                .spinner-item
                .spinner-item
        .main(v-if="tests.length > 0", :style="{ marginTop: marginTopMain }")
            h5.filter-input
                .input-group
                    input.form-control(placeholder="Filter" v-model="filter")
                    .input-group-append(@click="filter=''" v-if="filter")
                        .input-group-text &times;
            template(v-for="schedule in schedules", :key="schedule.id")
                div
                .top-time-entry.top-entry-execution(style="left: -10px") playing next
                    div(style="padding-top: 10px")
                        router-link.test-name.tu-link(:to="`/schedule/${schedule.id}`", style="margin-left: 16px") {{ schedule.name }}
            template(v-for="(execution, index_execution) in executions", :key="execution.id")
                a.top-time-entry.top-entry-execution(
                    v-if="showTimeData(index_execution)",
                    :class="{ 'top-entry-execution-no-schedule': !schedules.length }"
                ) {{ formatDate(execution.time) }}
                router-link.top-entry-execution-name.test-name.tu-link(
                    :to="`/execution/${execution.id}`",
                    :class="{ 'top-entry-execution-name-no-schedule': !schedules.length }"
                ) {{ execution.name.substring(0, 16) }}

            button.show-more.mr-5.btn.tu-btn(v-if="moreAvailable", @click="showMore")
                div
                    i.center.fas.fa-caret-right
                    span.ml-2 Show more
            .show-more-replacement(v-else)

            .testname-header.button-row.mb-2.pr-5
                v-action-button.invisible-in-print(
                    main="Create Test",
                    :mainIconClass="['fas', 'fa-plus-circle']",
                    @main="$emit('createTestCase')",
                    style="position: relative; left: 15px"
                )

                v-action-button.top-time-entry.create-schedule-btn(
                    :main="'Create Schedule'",
                    :mainIconClass="['fas', 'fa-plus-circle']",
                    @main="doCreateSchedule"
                )
            template(v-for="schedule in schedules", :key="schedule.id")
                .vertical-time-separator.schedule-column-separator.vertical-time-separator-top
                .play-schedule
                    v-action-button(
                        :main="'Play'",
                        :mainIconClass="['fas', 'fa-play']",
                        @main="playCheckedTests(schedule)",
                        :mainDisabled="schedulePlayDisabled(schedule)"
                    )

            .vertical-time-separator.schedule-column-separator(v-if="!executions.length")
            template(v-for="(execution, index_execution) in executions", :key="execution.id")
                .vertical-time-separator.vertical-time-separator-top(v-if="showTimeData(index_execution)")
                .result-row

            template(v-for="(testFlatten, index) in flattenedDependencyTreeFiltered", :key="testFlatten.testcase.id")
                test-name-header(
                    :testFlatten="testFlatten",
                    :countItemsByPredecessor="countItemsByPredecessor(testFlatten)",
                    :dropDownItems="dropDownItems(testFlatten.testcase)",
                    :parentTest="getItemByTestcaseId(testFlatten.testcase.predecessorId)",
                    :project="project"
                    @moveToProject="moveToProject"
                )
                template(v-for="schedule in schedules", :key="schedule.id")
                    .vertical-time-separator.schedule-column-separator
                    .testcase-checkbox(:class="{ 'checkbox-collapsed': testFlatten.isCollapsed }")
                        label
                            input(
                                type="checkbox",
                                :checked="!!getScheduleTestcase(schedule, testFlatten.testcase.id).active",
                                @change="activeChanged(schedule, testFlatten.testcase.id, $event)"
                            )
                            span
                .vertical-time-separator.schedule-column-separator(v-if="!executions.length")
                template(v-for="(execution, index_execution) in executions", :key="execution.id")
                    .vertical-time-separator(v-if="showTimeData(index_execution)")
                    .result-row(style="min-width: 13px")
                        template(
                            v-if="!!execution.testResults[testFlatten.index] && !testFlatten.isCollapsed",
                            v-for="recording in execution.testResults[testFlatten.index].recordings"
                        )
                            recording-cell-with-hover-panel(
                                :recording="recording"
                                :testCaseName="testFlatten.testcase.name"
                                :executionName="execution.name"
                                :height="30"
                                :width="execution.name != '' ? 30 : 5"
                                @click.prevent="openModal(recording.id, $event)"
                            )
        template(v-if="tests.length == 0")
            v-action-button.invisible-in-print(
                main="Create Test",
                :mainIconClass="['fas', 'fa-plus-circle']",
                @main="$emit('createTestCase')"
            )
            .main.mt-1
                v-help-text(@click="$emit('createTestCase')")
                    span= "You have not created a test case yet. Press "
                    span.link [Create Test]
                    span= " to start."
</template>

<style lang="css" scoped>
.divider {
    background-color: var(--black);
}
.executions {
    margin-top: 10px;
    min-height: max-content;
}

.container-block {
    min-height: max-content;
    min-width: max-content;
}

.main {
    margin-bottom: 120px;
    display: grid;
    grid-template-columns: max-content repeat(1000, max-content);
    grid-gap: 0;
    min-width: max-content;
    min-height: max-content;
}
.create-schedule-btn {
    position: relative;
    top: -20px;
}
.test-name {
    white-space: nowrap;
    width: 170px;
    margin-right: -170px;
    transform: translate(-60px, 0) rotate(-60deg) translate(90px, 0);
    color: var(--black);
}
.top-time-entry {
    white-space: nowrap;
    width: 200px;
    margin-right: -170px;
    transform: translate(-68px, 0) rotate(-60deg) translate(90px, 0);
    color: var(--light-grey) !important;
    text-decoration: none;
    font-size: 0.8rem;
}
.filter-input{
    margin: -70px 0 0 20px;
    width: 70%;
}
.testname-header {
    grid-column: 1;
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    min-width: 250px;
    max-width: 50vw;
}
.button-row {
    display: grid;
    grid-template-columns: 1fr max-content;
}
.result {
    margin: 4px;
    height: 30px;
    border-radius: 5px;
    vertical-align: middle;
}

.vertical-time-separator {
    width: var(--thin-line);
    background-color: var(--tu-border-lightgray);
    margin-left: auto;
    margin-right: auto;
}
.vertical-dependency-separator-open {
    min-width: var(--thin-line);
    width: var(--thin-line);
    height: calc(100%);
    min-height: 100%;
    min-height: -webkit-fill-available;
    min-height: -moz-available;
    margin-left: 6px;
    background-color: var(--tu-border-lightgray);
}
.vertical-dependency-separator-closed {
    min-width: var(--thin-line);
    width: var(--thin-line);
    height: calc(50%);
    margin-left: 6px;
    background-color: var(--tu-border-lightgray);
    display: inline-flex;
}
.vertical-separator {
    width: 18px;
    min-width: 18px;
    height: calc(100%);
    background-color: var(--white);
    display: inline-flex;
}
.passed {
    background-color: var(--bright-green);
}
.passed.baseline {
    background-color: white;
    border: 2px solid var(--bright-green);
}
.passed:hover {
    background-color: forestgreen;
}
.failed, .interrupted {
    background-color: var(--red);
}
.failed.baseline {
    background-color: white;
    border: 2px solid var(--red);
}
.failed:hover, .interrupted:hover {
    background-color: brown;
}
.failed.baseline:hover, .passed.baseline:hover {
    background-color: lightgray;
}
.separator {
    grid-column: 1 / 10;
    color: gray;
    font-size: 80%;
}
.marker {
    display: none;
}
.result-column {
    display: grid;
    grid-template-rows: repeat(1000, max-content);
}
.result-row {
    display: grid;
    grid-template-columns: repeat(1000, max-content);
    place-self: flex-start;
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
    top: -4px;
}
.show-more {
    transform: translate(10px, 54px);
}
.action-button {
    margin-top: auto;
    margin-bottom: auto;
}
.show-more-replacement {
    margin-right: 110px;
}
.play-schedule {
    align-self: center;
    justify-self: center;
    margin-right: -22px;
    margin-bottom: 10px;
}
.schedule-column-separator {
    margin-left: 14px;
    margin-right: 0;
}
.testcase-checkbox {
    align-self: center;
    justify-self: center;
    margin-right: -16px;
    padding-bottom: 6px;
}
.vertical-time-separator-top {
    position: relative;
}
.vertical-time-separator-top::before {
    position: absolute;
    top: -7px;
    left: 2px;
    content: "";
    height: 7px;
    width: 100%;
    border-bottom: var(--thin-line) solid var(--tu-border-lightgray);
    border-right: var(--thin-line) solid var(--tu-border-lightgray);
    transform: skew(-30deg);
}

.top-entry-execution {
    position: relative;
    left: 9px;
    top: 14px;
}

.top-entry-execution-no-schedule {
    left: -5px;
    top: -10px;
}

.top-entry-execution-name {
    position: relative;
    left: 9px;
    top: 25px;
}

.top-entry-execution-name-no-schedule {
    left: -5px;
    top: 0px;
}

</style>
