<script lang="ts" setup>
import { computed } from "vue"
import { listOfReorderedTestcase } from "@/services/TestcaseHelperFunction.js"
import type {ScheduledTestCaseDTO, ScheduleDTO} from "@/types/gen";

interface Props {
    schedule: ScheduleDTO
    tests: any[]
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "updateSchedule", data: any): void
    (event: "saveSchedule"): void
}>()

const reorderedTestsFromSchedule = computed(() => {
    return listOfReorderedTestcase(props.tests).map((testcase: any) => {
        let testcaseFromSchedule = getTestCaseById(testcase.id)
        if (!testcaseFromSchedule) {
            const schedule = { ...props.schedule, testCases: props.schedule.testCases }
            testcaseFromSchedule = {
                testCaseId: testcase.id,
                active: false,
                maxAttempts: 1,
                minPass: 1,
                stage: 1,
            } as ScheduledTestCaseDTO
            schedule!.testCases!.push(testcaseFromSchedule)
            emit("updateSchedule", schedule)
        } else {
            Object.assign(testcaseFromSchedule, {
                maxAttempts: testcaseFromSchedule.maxAttempts || 1,
                minPass: testcaseFromSchedule.minPass || 1,
                stage: testcaseFromSchedule.stage || 1,
            })
        }
        return { ...testcase, testcaseFromSchedule }
    })
})

const selectOptions = computed(() => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10])

function saveSchedule() {
    emit("saveSchedule")
}

function getTestCaseById(testCaseId: number) {
    return props.schedule.testCases?.find((row: any) => row.testCaseId == testCaseId)
}
</script>

<template lang="pug">
.timings
    .row.schedule-tests
        .tu-small
            .tests-table
                .table-cell.heading Testcase
                .table-cell.heading.text-center.ml-2.mr-2 Active
                .table-cell.heading.text-center.ml-2.mr-2 Stage
                .table-cell.heading.text-center.ml-2.mr-2 Max Attempts
                .table-cell.heading.text-center.ml-2.mr-2 Min Passed
                template(v-for="testcase in reorderedTestsFromSchedule", :key="testcase.id")
                    .table-cell.pt-2
                        router-link.tu-link(:to="`/testcase/${testcase.id}`") {{ testcase.name }}
                    .table-cell.table-cell-active.pt-2
                        .testcase-checkbox
                            label
                                input(
                                    type="checkbox",
                                    v-model="testcase.testcaseFromSchedule.active",
                                    @change="saveSchedule"
                                )
                                span
                    .table-cell.table-cell-select
                        select.custom-select(v-model="testcase.testcaseFromSchedule.stage", @change="saveSchedule")
                            option(v-for="option in selectOptions", :value="option", :key="option") {{ option }}
                    .table-cell.table-cell-select
                        select.custom-select(
                            v-model="testcase.testcaseFromSchedule.maxAttempts",
                            @change="saveSchedule"
                        )
                            option(v-for="option in selectOptions", :value="option", :key="option") {{ option }}
                    .table-cell.table-cell-select
                        select.custom-select(v-model="testcase.testcaseFromSchedule.minPass", @change="saveSchedule")
                            option(v-for="option in selectOptions", :value="option", :key="option") {{ option }}
</template>

<style lang="css" scoped>
.schedule-tests {
    margin-top: 20px;
}

.tests-table {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(5, auto);
    width: 80vw;
}
.heading {
    font-weight: 400;
    align-self: center;
    justify-items: center;
}

.table-cell {
    margin: 0 5px 10px 5px;
}

.table-cell-active {
    align-self: center;
    justify-self: center;
}

.table-cell-select {
    justify-self: center;
}

label {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.custom-select {
    margin-left: 15px;
    margin-right: 15px;
    width: 70px;
}

.checkbox-label {
    margin-left: 15px;
}
</style>
