<script lang="ts" setup>
import { onMounted, ref, watch } from "vue"
import { getRecording, getLatestPassedRecording } from "@/services/ProjectService"
import { useRoute } from "vue-router"
import Viewer from "@/common/components/viewer/Viewer.vue"
import type { RecordingDTO } from "@/types/gen";
import { DummyDeviceService, PendingDeviceService } from "@/services/BackendService";

const route = useRoute()

const recordingId = ref(parseInt(route.params.recordingId as string))
const testcaseId = ref(parseInt(route.params.testcaseId as string))
const initialFrame = ref(route.query.n || route.query.start || 0)
const autoplay = ref(route.query.autoplay === "true")
const end = ref(route.query.end || 0)
const navigation = ref(route.query.navigation === "false")
const recording = ref<RecordingDTO>()
const device = ref(new PendingDeviceService())

onMounted(resetRecording)

watch(
    () => route.query.n,
    (n) => {
        initialFrame.value = parseInt(n as string) || 0
    }
)

async function resetRecording() {
    if (recordingId.value) {
        recording.value = await getRecording(recordingId.value)
    } else {
        recording.value = await getLatestPassedRecording(testcaseId.value)
    }
    device.value = new DummyDeviceService(recording.value)
    recording.value.frames = recording.value.frames.filter((frame: any) => frame.imageUrl)
}
</script>

<template lang="pug">
.recording-content
    viewer.viewer-component-wrapper(
        v-if="recording != null",
        :recording="recording",
        :device="device"
        :initialFrame="initialFrame",
        :autoplay="autoplay",
        :navigation="navigation",
        :lastFrame="end"
    )
</template>

<style lang="css" scoped>
.recording-content {
    height: 100vh;
}
.recording-content::v-deep .hide-in-iframe {
    display: none;
}
</style>