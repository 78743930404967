<script lang="ts" setup>
import { nextTick, ref, watch } from "vue"
import { saveConfig } from "@/services/ProjectService"
import VActionButton from "@/common/components/form/VActionButton.vue"
import AquaSettingsData from "@/common/aqua/AquaSettingsData.vue"
import VHelpText from "@/common/components/layout/VHelpText.vue"
import VPassword from "@/common/components/form/VPassword.vue"
import { computed } from "@vue/reactivity"

interface Props {
    variables: any
    device: any
    testcase?: any
    viewerMode?: boolean
    metaData?: any
    showUseVariable?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "save", data: any): void
    (event: "useVariable", data: any): void
}>()
defineExpose({
  addVariable,
  syncAqua
})

const PLUGIN_AQUA_URL = ref<string>((window as any).PLUGIN_AQUA_URL)
const aquaSettingsData = ref<typeof AquaSettingsData>()
const focusLastVar = ref(false)
const showMetaData = ref(false)

watch(
    () => props.variables,
    async (newVal) => {
        if (focusLastVar.value) {
            focusLastVar.value = false
            await focusLastVariable()
        }
    }
)

const hasVariables = computed(() => {
    return props.variables.find((x:any) => x.variables.length>0)
})

function getTestCaseVariables() {
    return (props.variables.find((row: any) => row.type == 'TEST_CASE')).variables
}

async function addVariable({ name = "", value = "", allowDuplicate = true, shouldSave = true, hidden=false }: { name?: any, value?: any, allowDuplicate?: boolean, shouldSave?: boolean, hidden?: false }) {
    const testCaseVars = getTestCaseVariables()
    if (allowDuplicate) {
        testCaseVars.push({ name, value, hidden })
    } else {
        const foundVar = testCaseVars.find((variable: any) => variable.name == name)
        if (foundVar) {
            foundVar.value = value
        } else {
            testCaseVars.push({ name, value, hidden })
        }
    }
    focusLastVar.value = true
    if (shouldSave) save()
}

async function focusLastVariable() {
    await nextTick()
    const varNameInputs = document.querySelectorAll('.var-name')
    const lastVarNameInput = varNameInputs[varNameInputs.length - 1] as HTMLInputElement
    if (lastVarNameInput)
        lastVarNameInput.focus()
}

function deleteVariable(variable: any) {
    const testCaseVars = getTestCaseVariables()
    testCaseVars.splice(testCaseVars.indexOf(variable), 1)
    save()
}

function cloneVariable(variable: any) {
    addVariable({ name: variable.name, value: variable.value })
}

function save() {
    const testCaseVars = getTestCaseVariables()
    const testCaseVarsData = JSON.parse(JSON.stringify(testCaseVars))
    emit("save", testCaseVarsData)
}

function useVariable(variable: any) {
    emit("useVariable", variable)
}

function syncAqua(message: any) {
    switch(message) {
        case "import":
            aquaSettingsData.value!.openImportDialog()
            break;
        case "export":
            aquaSettingsData.value!.openExportDialog()
            break;
        case "open":
            aquaSettingsData.value!.openExtern()
            break;
        default:
            throw new Error("Unknown message "+message)
    }
}

</script>

<template lang="pug">
.config-container.tu-tab-area
    .settings-container
        template(v-if="PLUGIN_AQUA_URL")
            .empty
            aqua-settings-data(ref="aquaSettingsData" :config="testcase.config" @addVariable="addVariable", @save="save")
        template(v-if="!viewerMode")
            .variables-section
                .variables-section-header RUNTIME
                .empty
                template(v-for="(value, key) of device.runtimeVariables")
                    .var-name.form-row.mb-0.ml-4
                        label
                            .input-group
                                .input-group-prepend
                                    .input-group-text $
                                input.form-control.bg-white(:value="key" disabled)
                    .use-button
                        .deleteBtn(v-if="showUseVariable" @click.prevent="useVariable({php:'$' + key})")
                            i.fas.fa-code
                    div
                        input.form-control.bg-white(:value="value" disabled)
                    .empty
                        // no options yet

                v-help-text.ml-3(v-if="!hasVariables" @click="addVariable")
                    span= "No variables are defined "
                    span.link [Add variable]
                template(v-if="true")
                    template(v-for="(varsSection, index) of variables", :key="index")
                        .variables-section-header {{varsSection.type}}
                            span(v-if="varsSection.testCaseName")  - {{varsSection.testCaseName}}
                        .empty
                        template(v-for="(variable, indexVar) of varsSection.variables", :key="indexVar")
                            .form-row.var-name.mb-0.ml-4
                                label
                                    .input-group
                                        .input-group-prepend
                                            .input-group-text $
                                        input.form-control.var-name(
                                            placeholder="name",
                                            v-model="variable.name",
                                            :class="{'overriden': variable.isOverridden, disabled: viewerMode}",
                                            :disabled="viewerMode || !variable.isEditable",
                                            @change="save")
                            .use-button
                                .deleteBtn(v-if="showUseVariable" @click.prevent="useVariable(variable)")
                                    i.fas.fa-code
                            div(v-if="!variable.hidden")
                                input.form-control.d-inline.var-value(
                                    placeholder="value",
                                    v-model="variable.value",
                                    :class="{'overriden': variable.isOverridden, disabled: viewerMode}",
                                    :disabled="viewerMode || !variable.isEditable",
                                    @change="save",
                                    style="width: 100%")
                            div(v-else)
                                v-password(v-model="variable.value" @update:modelValue="save()")
                            div.varIcons
                                .varBtn(v-if="!viewerMode && varsSection.type=='TEST_CASE'" @click="deleteVariable(variable)")
                                    i.fas.fa-trash
                                .varBtn(v-if="!viewerMode && varsSection.type=='PREDECESSOR' && !variable.isOverridden && !variable.hidden" @click="cloneVariable(variable)")
                                    i.fa.fa-clone
                    .empty
        template(v-else)
            .variables-section-name.section-name
            .variables-section
                template(v-if="true")
                    template(v-if="true")
                        .variables-section-header RUNTIME
                        .empty
                        template(v-for="(value, key) of device.runtimeVariables")
                            .var-name.form-row.mb-0.ml-4
                                label
                                    .input-group
                                        .input-group-prepend
                                            .input-group-text get()
                                        input.form-control.bg-white(:value="key" disabled)
                            .use-button
                            div
                                input.form-control.kot.bg-white(:value="value" disabled)
                            .empty
                        .empty
                    .variables-section-header RECORDING
                    .empty
                    template(v-for="(variable, index) of variables", :key="index")
                        .form-row.var-name.mb-0.ml-4
                            label
                                .input-group
                                    .input-group-prepend
                                        .input-group-text $
                                    input.form-control.var-name(
                                        placeholder="name",
                                        v-model="variable.name",
                                        :class="{disabled: viewerMode}",
                                        :disabled="viewerMode")
                        .use-button
                        div(v-if="!variable.hidden")
                            input.form-control.d-inline.var-value(
                                placeholder="value",
                                v-model="variable.value",
                                :class="{disabled: viewerMode}",
                                :disabled="viewerMode",
                                style="width: 100%")
                        div(v-else)
                            span *** hidden ***
                        div
                    .empty
    .ml-4(v-if="metaData")
        .tu-small.tu-link(@click="showMetaData=!showMetaData") Show meta data
        pre(v-if="showMetaData") {{ metaData }}
</template>

<style lang="css" scoped>
.form-group {
    display: grid;
    grid-template-columns: 3fr 7fr;
    grid-row-gap: 1ex;
}
.varBtn {
    cursor: pointer;
    width: fit-content;
    padding-right: 6px;
}

.varIcons{
    display: grid;
    grid-auto-flow: column;
    padding-right: 6px;
    margin-left: 5px;
}

.settings-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, max-content);
    grid-gap: 10px;
    padding-bottom: 50px;
    margin-top: 50px;
    min-width: 300px;
}

.space {
    grid-column: 1/3;
    height: 50px;
}

.variables-section {
    display: grid;
    grid-template-columns: 1.3fr min-content 1fr min-content;
    align-items: center;
    row-gap: 10px;
}

.variables-section-header {
    margin-top: 2ex;
    padding-left: 3ex;
    grid-column: 1 / 4;
}

.var-name label {
    width: 100%;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.use-button {
    min-width: 5px;
    margin-left: 8px;
    margin-right: 6px;
}
.use-button:hover {
    background-color: lightgray;
    border-radius: 5px;
}

.disabled {
    pointer-events: none;
}

.overriden {
    text-decoration: line-through;
}
</style>
